'use client';

import { ComponentProps } from 'react';

import { ModalProvider } from '@/components/momentum/components/modal';

import { STORAGE_KEY_PREFIX } from '../constants';

/**
 * Due to a security issue with devops we must run server actions
 * triggered by the client temporarily, please switch to using
 * the server action cookie solution once resolved
 */

type Props = { id: string; showOnce?: boolean } & ComponentProps<
  typeof ModalProvider
>;

const ClientModalProvider = ({
  onClose,
  children,
  id,
  showOnce,
  ...props
}: Props) => {
  const storageKey = `${STORAGE_KEY_PREFIX}_${id}`;
  const seenModal = localStorage.getItem(storageKey) && showOnce;

  const handleOnClose = () => {
    if (showOnce) {
      localStorage.setItem(`${STORAGE_KEY_PREFIX}_${id}`, 'true');
    }
  };

  return !seenModal ? (
    <ModalProvider
      {...props}
      onClose={() => {
        handleOnClose();
        onClose?.();
      }}
    >
      {children}
    </ModalProvider>
  ) : null;
};

export { ClientModalProvider };
