'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Box } from '@virginmediao2/momentum-nextjs/box';
import { FlexBlock } from '@virginmediao2/momentum-nextjs/flex-block';
import { FormInput } from '@virginmediao2/momentum-nextjs/forms';
import { Text } from '@virginmediao2/momentum-nextjs/text';
import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-dom';
import { useForm } from 'react-hook-form';

import { deviceModelNumberAction } from '@/lib/imei-checker';
import { DeviceEntry } from '@/lib/imei-checker/parseCsv';

import styles from './imei.module.scss';
import {
  DeviceCheckerFormProps,
  Notifications,
  deviceModelNumberSchema,
} from './utils';

const mainCopy = `Enter your device’s model number below.`;

export const DeviceModelNumberForm = (props: DeviceCheckerFormProps) => {
  const [selectedDevice, setSelectedDevice] = useState<null | DeviceEntry>(
    null
  );

  const [actionStatus, actionHandler] = useFormState(deviceModelNumberAction, {
    error: undefined,
    payload: undefined,
  });

  const form = useForm<z.infer<typeof deviceModelNumberSchema>>({
    resolver: zodResolver(deviceModelNumberSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    errors: actionStatus.fieldError,
  });

  useEffect(() => {
    if (actionStatus.payload) setSelectedDevice(actionStatus.payload[0]);
  }, [
    actionStatus.payload?.reduce(
      (sum, item) => `${sum}${item['TAC Code']}`,
      ``
    ),
  ]);

  return (
    <Box border={false}>
      <Text size='T400'>Device model number</Text>
      <Box gap='md' border={false}>
        <form
          onSubmit={form.handleSubmit((data) => {
            const payload: {
              deviceModelNumber: string;
              url?: string;
            } = {
              deviceModelNumber: data.deviceModelNumber,
            };

            if (props.url) {
              payload.url = props.url;
            }

            actionHandler(payload);
          })}
        >
          <FlexBlock gap='md' align='end' padding='xs'>
            <div className={styles['form__item--min-length']}>
              <FormInput
                {...form.register('deviceModelNumber')}
                id='deviceModelNumber'
                label={<Text>{mainCopy}</Text>}
                placeholder='i.e. A3090'
                error={form.formState.errors.deviceModelNumber}
              />
            </div>
            <div className={styles['submit-button-container']}>
              <FormInput type='submit' name='find' id='find' value='Find' />
            </div>
          </FlexBlock>
        </form>
        <Notifications
          actionStatus={actionStatus}
          form={form as any}
          selectedDevice={selectedDevice}
          setSelectedDevice={setSelectedDevice}
        />
      </Box>
    </Box>
  );
};
