'use client';

import { Box } from '@virginmediao2/momentum-nextjs/box';
import { Flex } from '@virginmediao2/momentum-nextjs/flex';
import { FlexBlock } from '@virginmediao2/momentum-nextjs/flex-block';
import { FormSelect } from '@virginmediao2/momentum-nextjs/forms';
import { Text } from '@virginmediao2/momentum-nextjs/text';
import Image from 'next/image';
import React, { FC, useEffect, useState } from 'react';

import { BoltOn, getBoltOnData } from './get-bolt-ons';

export interface InternationalBoltOnProps {
  json_path: string;
  label: string;
  defaultCountry: string;
  imageAssetFolder: string;
}

export const InternationalSimBoltOn: FC<InternationalBoltOnProps> = (props) => {
  const [boltOns, setBoltons] = useState<BoltOn[]>([]);
  const [selectedBoltOn, setSelectedBoltOn] = useState<BoltOn | null>(null);

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const getBoltOns = async () => {
      const url = props.json_path;
      setError(false);
      const response = await getBoltOnData(url);
      if (response.length > 0) {
        const defaultCountry = props.defaultCountry;
        if (defaultCountry) {
          const defaultItem = response.find(
            (boltOn) =>
              boltOn.name.toLowerCase().trim() ===
              defaultCountry.toLowerCase().trim()
          );
          if (defaultItem) {
            setSelectedBoltOn(defaultItem);
          }
        }
        setBoltons(response);
      } else {
        setError(true);
      }
    };

    getBoltOns();
  }, []);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    const foundItem =
      boltOns.find((boltOn) => boltOn.id === selectedId) || null;
    setSelectedBoltOn(foundItem);
  };

  return (
    <>
      {error && (
        <Text>
          There was a problem getting the International Sim Bolt-Ons data.
        </Text>
      )}
      {boltOns?.length > 0 && (
        <FormSelect
          label={props.label}
          id='country-select'
          name='country-select'
          onChange={handleSelectChange}
          value={selectedBoltOn?.id ?? ''}
        >
          {boltOns.map((boltOn) => (
            <option key={boltOn.id} value={boltOn.id}>
              {boltOn.name} ({boltOn.alpha3})
            </option>
          ))}
        </FormSelect>
      )}
      {selectedBoltOn && (
        <FlexBlock gap='lg' padding={'sm 0'}>
          <div>
            {selectedBoltOn.image ? (
              <Image
                src={props.imageAssetFolder + selectedBoltOn.image}
                width={64}
                height={64}
                alt={selectedBoltOn.name}
              ></Image>
            ) : (
              <Text size='T800'>{selectedBoltOn.name}</Text>
            )}
          </div>
          <div>
            <Box border={false}>
              <Text>Landline</Text>
            </Box>
            {selectedBoltOn.landline ? (
              <Flex gap='xs' align='end'>
                <Text size='T800'>{selectedBoltOn.landline}</Text>
                <Text>p/min</Text>
              </Flex>
            ) : (
              <Text size='T800'>N/A</Text>
            )}
          </div>
          <div>
            <Box border={false}>
              <Text>Mobile</Text>
            </Box>
            {selectedBoltOn.mobile ? (
              <Flex gap='xs' align='end'>
                <Text size='T800'>{selectedBoltOn.mobile}</Text>
                <Text>p/min</Text>
              </Flex>
            ) : (
              <Text size='T800'>N/A</Text>
            )}
          </div>
          <div>
            <Box border={false}>
              <Text>Text</Text>
            </Box>
            {selectedBoltOn.texts ? (
              <Flex gap='xs' align='end'>
                <Text size='T800'>{selectedBoltOn.texts}</Text>
                <Text>p/text</Text>
              </Flex>
            ) : (
              <Text size='T800'>N/A</Text>
            )}
          </div>
        </FlexBlock>
      )}
    </>
  );
};
