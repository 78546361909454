export interface ShortCode {
  shortcode?: string;
  type_of_service?: string;
  service_or_product?: string;
  service_or_product_dscrptn?: string;
  content_provider?: string;
  how_do_users_strt_the_srvc?: string;
  how_do_users_stp_the_srvc?: string;
  website_address?: string;
  cstmr_care_cntct_nmbr?: string;
  email_id?: string;
  last_updated?: string;
  object_id?: string;
  Top100?: string;
}

export interface Merchant {
  YC?: string;
  'Merchant name'?: string;
  'Service Description'?: string;
  'Service Name'?: string;
  'Service category'?: string;
  'Web url'?: string;
  'How do you start the service'?: string;
  'How do you stop the service'?: string;
  'Is this a subscription service'?: string;
  'Is the service STOP compliant'?: string;
  'Content category i.e Game/Betting'?: string;
  'Cost of service'?: string;
  'Is this an 18+ service'?: string;
  'Customer service number'?: string;
  'Customer service email address'?: string;
  'Company web site address'?: string;
  'Date last updated'?: string;
}

export enum Service {
  StandardCharge = 'standardCharge',
  Directory = 'directoryEnquiryNumber',
  NonGeographicNumber = 'nonGeographicalNumber',
  PremiumNumber = 'premiumNumber',
  PremiumText = 'premiumText',
}

export type ServiceType = `${Service}`;
export interface ServiceCharge {
  reference: string;
  title: string;
  descriptionType?: ServiceType;
  service?: string;
  category?: string;
  contactNo?: string;
  stopContent?: string;
}
