/* eslint-disable react/no-unescaped-entities */
import Link from 'next/link';
import { FC } from 'react';

import { Text } from '@/components/momentum/components/text';

import styles from './EuroZoneInfo.module.css';

export const EuroZoneInfo: FC = () => {
  return (
    <div className={styles['eurozone']} data-testid='eurozone-info'>
      <div className={styles['eurozone__section']}>
        <Text as='p' size='T300' weight='bold' marginBottom>
          Travelling in the O2 Europe Zone?
        </Text>

        <Text as='p' size='T300' weight='regular' marginBottom>
          We’re the only major network that isn’t bringing back EU roaming
          charges for up to 25GB. Wherever you fancy going in our Europe Zone
          (40+ destinations), you’re free to call, text and use data just like
          you would in the UK, subject to a{' '}
          <Link href='https://www.o2.co.uk/roamfairuse' target='_blank' prefetch={false}>
            roaming limit
          </Link>
          .
        </Text>
      </div>
      <div className={styles['eurozone__section']}>
        <Text as='p' size='T300' weight='bold' marginBottom>
          What if I’m travelling outside the O2 Europe Zone?
        </Text>
        <Text as='p' size='T300' weight='regular' marginBottom>
          If you're a{' '}
          <Link
            href='https://www.virginmediao2.co.uk/?buspart=uk_ps_cus_cable_GOOGLE_Cable_Acq_Brand_JVCO_Exact_Brand_JVCO_volt%20o2&amp;CMP=DFA&amp;medium=PPC&amp;gclid=EAIaIQobChMIgv_mkPyn-gIVj9_tCh3RSAGdEAAYASAAEgKvgfD_BwE&amp;gclsrc=aw.ds'
            target='_blank'
            prefetch={false}
          >
            Volt
          </Link>{' '}
          customer or you're on a{' '}
          <Link
            href='https://www.o2.co.uk/plus-plans'
            rel='noopener noreferrer'
            target='_blank'
            prefetch={false}
          >
            Plus Plan
          </Link>
          , you get inclusive roaming in 27 destinations outside Europe with our
          O2 Travel Inclusive Zone. This gives you unlimited minutes, texts and
          data roaming at no extra cost. If you’re on an{' '}
          <Link
            href='https://www.o2.co.uk/ultimate-plan'
            rel='noopener noreferrer'
            target='_blank'
            prefetch={false}
          >
            Ultimate Plan
          </Link>
          , you get inclusive roaming in 75 destinations outside Europe with our{' '}
          <Link
            href='/international/travel-inclusive-zone-ultimate'
            rel='noopener noreferrer'
            prefetch={false}
          >
            O2 Travel Inclusive Zone Ultimate
          </Link>
          .
        </Text>
      </div>
      <div className={styles['eurozone__section']}>
        <Text as='p' size='T300' weight='bold' marginBottom>
          What if I don't have the O2 Travel Inclusive Bolt On or am travelling
          outside the O2 Travel Inclusive Zone?
        </Text>
        <Text as='p' size='T300' weight='regular' marginBottom>
          If you’re on Pay Monthly, you can still get our full{' '}
          <Link href='/international/o2-travel' target='_blank' prefetch={false}>
            O2 Travel Bolt On
          </Link>{' '}
          for £6 a day in selected destinations. If you’re on Pay As You Go, you
          can get our O2 Travel Bolt On for £1.99 a day in selected
          destinations. You'll be charged our standard rates if you don't have
          any of our O2 Travel Bolt Ons.
        </Text>
      </div>
    </div>
  );
};
