import { isRichTextEmpty } from '@storyblok/js';
import clsx from 'clsx';

import { Content } from '@virginmediao2/momentum-nextjs/content';

import { DescriptionList } from '@/components/momentum/components/description-list';
import { HorizontalRule } from '@/components/momentum/components/horizontal-rule';
import { Text } from '@/components/momentum/components/text';
import { IStoryblokComponent } from '@/components/storyblok/renderer';
import { RenderRichText } from '@/components/storyblok/utils/render-richtext';
import { toPrice } from '@/lib/utils/money';

import { useRates } from '../hooks/use-rates';
import { IntlCallRatesBlok } from '../types';
import styles from './CountryRates.module.css';

export const PayMonthly = ({
  blok,
  StoryblokComponent,
}: {
  blok: IntlCallRatesBlok;
  StoryblokComponent: IStoryblokComponent;
}) => {
  const { paym_inbound, paym_outbound, paym_sms, paym_data } = useRates(blok);

  const filteredItems = [
    { term: 'Making Calls', description: paym_outbound, unit: 'min' },
    { term: 'Receiving Calls', description: paym_inbound, unit: 'min' },
    { term: 'Sending Texts', description: paym_sms, unit: 'text' },
    { term: 'Using Data', description: paym_data, unit: 'MB' },
  ].filter((item) => item.description.toLowerCase() !== 'unknown');

  return (
    <>
      <div
        className={clsx(
          styles['country__rates'],
          styles['country__rates--paym']
        )}
      >
        {blok.paym_zone_info && !isRichTextEmpty(blok.paym_zone_info) && (
          <>
            <Content>
              <RenderRichText
                document={blok.paym_zone_info}
                StoryblokComponent={StoryblokComponent}
              />
            </Content>
            <HorizontalRule spacing={'md'} />
          </>
        )}

        {blok.paym_message && (
          <div className={styles['country__rates--paym-msg']}>
            <Text>{blok.paym_message}</Text>
          </div>
        )}

        <div className={styles['country__rates-table']}>
          <div className={styles['country__rates-tablehead']}>
            <Text>Standard Rates</Text>
          </div>
          <DescriptionList ratio={'1:1'} list={filteredItems} />
        </div>
      </div>

      {blok.paym_roaming_bundles?.length > 0 && (
        <div
          className={clsx(
            styles['country__rates'],
            styles['country__rates--roaming']
          )}
        >
          <Text marginBottom>{blok.paym_bundle_prompt}</Text>

          <div className={styles['country__rates-table']}>
            <DescriptionList
              ratio={'1:1'}
              list={blok.paym_roaming_bundles.map((bundle) => {
                return {
                  term: `${bundle.name}`,
                  description: toPrice(bundle.price),
                };
              })}
            />
          </div>
        </div>
      )}
    </>
  );
};
