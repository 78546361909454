import type { ReactElement } from 'react';
import { createElement, useMemo } from 'react';

import type { IconObject } from './use-icon.hook';

interface SvgObject {
  element: string;
  children: Array<SvgObject>;

  [key: string]: string | Array<SvgObject>;
}

export const useSvg = (svgObject: IconObject): ReactElement<any> | null =>
  useMemo(() => {
    if (!svgObject.icon) {
      return null;
    }

    const { element, children, ...attributes } = svgObject.icon;

    // Create an array to store child elements
    const childElements = children.map((child, index) => {
      const { element: childElement, ...childAttributes } = child as SvgObject;

      if (childAttributes['fill-rule']) {
        childAttributes['fillRule'] = childAttributes['fill-rule'];
        delete childAttributes['fill-rule'];
      }

      if (childAttributes['clip-rule']) {
        childAttributes['clipRule'] = childAttributes['clip-rule'];
        delete childAttributes['clip-rule'];
      }

      if (childAttributes['clip-path']) {
        childAttributes['clipPath'] = childAttributes['clip-path'];
        delete childAttributes['clip-path'];
      }

      return createElement(childElement, { key: index, ...childAttributes });
    });

    // Create the main SVG element with attributes and child elements
    return createElement(
      element,
      { ...attributes, role: 'img', 'aria-hidden': true },
      childElements
    );
  }, [svgObject]);
