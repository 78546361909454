'use client';

import clsx from 'clsx';

import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import {
  ComponentPropsWithoutRef,
  FC,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useInterval } from 'react-use';

import { Loader } from '@/components/momentum/components/loader';

import styles from './iframe-embed.module.css';

export interface IFrameEmbedProps
  extends Omit<
    ComponentPropsWithoutRef<'iframe'>,
    'onLoad' | 'width' | 'scrolling' | 'frameBorder'
  > {
  src: string;
  height?: number;
  iframeResizer?: boolean;
}

export const IFrameEmbed: FC<IFrameEmbedProps> = ({
  src,
  height,
  iframeResizer,
  ...props
}: IFrameEmbedProps) => {
  const searchParams = useSearchParams();
  const ref = useRef<HTMLIFrameElement>(null);

  const [iframeHeight, setIframeHeight] = useState<number>(height ?? 200);
  const [loaded, setLoaded] = useState<boolean>(false);

  useInterval(() => {
    if (!height && !iframeResizer && ref.current?.contentWindow) {
      const { contentWindow } = ref.current;
      setIframeHeight(contentWindow.document.body?.scrollHeight);
    }
  }, 200);

  const onLoad = useCallback(() => {
    if (ref.current) {
      setLoaded(true);
    }
  }, [ref]);

  return (
    <div
      className={clsx({
        [styles['embed']]: true,
        [styles['embed--loaded']]: loaded,
      })}
    >
      <div className={styles['embed__container']}>
        <iframe
          id='embededIframe'
          src={`${src}${searchParams && searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`}
          ref={ref}
          onLoad={onLoad}
          width='100%'
          height={`${iframeHeight}px`}
          scrolling='no'
          frameBorder='0'
          {...props}
        ></iframe>
        {iframeResizer && (
          <Script
            type='text/javascript'
            src='/scripts/iframe-resizer/iframe-resizer.min.js'
            onLoad={() => window.iFrameResize({ log: false }, '#embededIframe')}
          />
        )}
      </div>
      {!loaded && (
        <div className={styles['embed__loader']}>
          <Loader />
        </div>
      )}
    </div>
  );
};
