import { sendRequest } from './send-request';

export const sendSupportRequest = async (data: Record<string, any>) => {
  const formData: Record<string, unknown> = {
    firstName: data.firstName,
    lastName: data.lastName,
    o2Number:
      data.requestFor == 'another'
        ? data.requestForAnotherNumber
        : data.mobileNumber,
    emailAddress: data.email,
    contactPreference: data.contactPreference,
    reason: [
      `Account holder: ${data.accountHolder}`,
      `Nominate: ${data.nominate.toUpperCase()} - ${data.nominee ?? 'n/a'}`,
      `Support needs: ${data.support}`,
      `Consent given: ${data.consent ? 'YES' : 'NO'}`,
      `Other contact prefrence: ${data.contactPreferenceOtherDetail ?? 'n/a'}`,
    ].join('\n\n'),
  };

  return await sendRequest(formData);
};
