import { z } from 'zod';

import { Alert } from '@virginmediao2/momentum-nextjs/alert';
import { Tab, Tabs } from '@virginmediao2/momentum-nextjs/tabs';
import { UseFormReturn } from 'react-hook-form';

import { DeviceEntry } from '@/lib/imei-checker/parseCsv';

import styles from './imei.module.scss';

const requiredErrorMessage = 'Please fill out this required value.';

const imeiCharacterErrorMessage = `Your IMEI should be numbers only. Please enter the first 8 digits.`;

export interface DeviceCheckerFormProps {
  url: string;
}

export const deviceNameSchema = z.object({
  deviceName: z.string().min(1, requiredErrorMessage),
});

export const deviceModelNumberSchema = z.object({
  deviceModelNumber: z.string().min(1, requiredErrorMessage),
});

export type DeviceModelNumberSchemaKeys = keyof z.infer<
  typeof deviceModelNumberSchema
>;

export type ImeiNumberSchemaKeys = keyof z.infer<typeof imeiNumberSchema>;

export type DeviceNameSchemaKeys = keyof z.infer<typeof deviceNameSchema>;

export const imeiNumberSchema = z.object({
  imeiNumber: z
    .string()
    .min(1, requiredErrorMessage)
    .regex(/^\d{8}$/, imeiCharacterErrorMessage),
});

type ConstructAlertPayload =
  | {
      message: string | React.ReactNode;
      status: 'success' | 'error';
    }
  | undefined;

export const volteIncompatibleMessage = (deviceDescription: string) => (
  <span>
    Sorry - your {deviceDescription} isn’t compatible with 4G calling. This
    means you won’t be able to use it on our network when we switch off 3G in
    your area, so you’ll need to upgrade to a compatible device soon.{' '}
    <a
      href='https://accounts.o2.co.uk/'
      target='_blank'
      rel='noopener noreferrer'
    >
      Sign in to My O2
    </a>{' '}
    to see your upgrade options. Or take a look at our range of{' '}
    <a
      href='https://www.o2.co.uk/shop/phones?cnd=new'
      target='_blank'
      rel='noopener noreferrer'
    >
      4G and 5G devices
    </a>
    .
  </span>
);

export const deviceNotFoundMessage = (
  <span>
    Sorry, we couldn’t find your device. Please check your user guide or the
    manufacturer’s website to see if your device is compatible.{' '}
    <a
      href='https://accounts.o2.co.uk/'
      target='_blank'
      rel='noopener noreferrer'
    >
      Sign in to My O2
    </a>{' '}
    to see your upgrade options. Or take a look at our range of{' '}
    <a
      href='https://www.o2.co.uk/shop/phones?cnd=new'
      target='_blank'
      rel='noopener noreferrer'
    >
      4G and 5G devices
    </a>
    . Business customers can sign in to{' '}
    <a
      href='https://www.o2.co.uk/business/myo2business'
      target='_blank'
      rel='noopener noreferrer'
    >
      My O2 Business here
    </a>
    .
  </span>
);

export const constructAlert = (
  selectedDevice: DeviceEntry | null | undefined
): ConstructAlertPayload => {
  if (!selectedDevice) {
    return;
  }

  if (selectedDevice['VoLTE Capable'].trim() !== 'Yes') {
    return {
      message: volteIncompatibleMessage(selectedDevice['Device Description']),
      status: 'error',
    };
  }

  return {
    message: `Your device ${selectedDevice['Device Description'].trim()} is compatible with 4G calling.`,
    status: 'success',
  };
};

export const Notifications = ({
  selectedDevice,
  form,
  actionStatus,
  setSelectedDevice,
}: {
  selectedDevice: DeviceEntry | null;
  form: UseFormReturn;
  actionStatus: { payload?: DeviceEntry[] };
  setSelectedDevice: (entry: DeviceEntry) => void;
}) => {
  const derivedData = constructAlert(selectedDevice);

  return (
    <>
      {actionStatus.payload?.length === 0 && form.formState.isSubmitted && (
        <Alert title='Device information unavailable' variant='error'>
          {deviceNotFoundMessage}
        </Alert>
      )}
      {Boolean(actionStatus.payload?.length) && (
        <Tabs
          controls={(actionStatus.payload?.length ?? 0) > 2}
          breakout={(actionStatus.payload?.length ?? 0) > 2}
        >
          {actionStatus.payload?.map((result) => (
            <Tab
              key={result['TAC Code']}
              active={selectedDevice?.['TAC Code'] === result['TAC Code']}
              onClick={() => {
                setSelectedDevice(result);
              }}
              className={styles['tab--no-overflow']}
            >
              {result['Device Description']}
            </Tab>
          ))}
        </Tabs>
      )}
      {actionStatus.payload?.length !== 0 &&
        derivedData?.status === 'success' && (
          <Alert title='Great news!' variant='success'>
            {derivedData.message}
          </Alert>
        )}
      {actionStatus.payload?.length !== 0 &&
        derivedData?.status === 'error' && (
          <Alert title={`Not compatible`} variant='error'>
            {derivedData.message}
          </Alert>
        )}
    </>
  );
};
