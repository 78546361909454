'use client';

import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import { Video, VideoProps } from '@/components/momentum/components/video';

import { CoercedImage } from '../../utils/coerce-image';
import { CoercedVideo } from '../../utils/coerce-video';

export interface ResponsiveVideoProps
  extends Omit<VideoProps, 'src' | 'title' | 'description' | 'thumbnailUrl'> {
  thumbnailUrl?: CoercedImage;
  sm?: CoercedVideo;
  md?: CoercedVideo;
  lg?: CoercedVideo;
  xl?: CoercedVideo;
  alt: string;
  type?: string;
}

export const ResponsiveVideo = ({
  sm,
  md,
  lg,
  xl,
  alt,
  thumbnailUrl,
  type = 'video/mp4',
  ...props
}: ResponsiveVideoProps) => {
  const isSmBreakpoint = useMedia('(width <= 48em)', false);
  const isMdBreakpoint = useMedia('(width >= 48em) and (width < 64em)', false);
  const isLgBreakpoint = useMedia('(width >= 64em) and (width < 90em)', false);
  const isXlBreakpoint = useMedia('(width >= 90em)', false);
  // XL is (width >= 90em)

  const [video, setUpdatedVideo] = useState<CoercedVideo | undefined>(
    undefined
  );

  useEffect(() => {
    setUpdatedVideo(() => {
      if (isSmBreakpoint) return sm;
      if (isMdBreakpoint) return md;
      if (isLgBreakpoint) return lg;
      if (isXlBreakpoint) return xl;
      return undefined;
    });
  }, [
    isSmBreakpoint,
    isMdBreakpoint,
    isLgBreakpoint,
    isXlBreakpoint,
    video,
    sm,
    md,
    lg,
    xl,
  ]);

  return (
    <Video
      {...props}
      thumbnailUrl={thumbnailUrl?.src}
      src={video ? video.src : ''}
      description={alt}
      title={xl?.title}
      dynamicSrcLoad={true}
    >
      {xl && <source src={xl.src} type={type} media='(width >= 90em)' />}
      {lg && (
        <source
          src={lg.src}
          type={type}
          media='(width >= 64em) and (width < 90em)'
        />
      )}
      {md && (
        <source
          src={md.src}
          type={type}
          media='(width >= 48em) and (width < 64em)'
        />
      )}
      {sm && <source src={sm.src} type={type} media='(width <= 48em)' />}
    </Video>
  );
};
