import { FC } from 'react';

import { Anchor } from '@/components/momentum/components/anchor';
import DynamoImageLink from '@/components/storyblok/bloks/dynamo-image-link';
import { DynamoImageLinkBlok } from '@/components/storyblok/bloks/dynamo-image-link';
import { DynamoLinkBlok } from '@/components/storyblok/bloks/dynamo-link';
import { DynamoNavigation } from '@/components/storyblok/types/dynamo-navigation';
import { coerceUrl } from '@/components/storyblok/utils/coerce-url';

import styles from './l3-category.module.scss';

export interface L3CategoryProps {
  category: DynamoNavigation;
}

export const L3Category: FC<L3CategoryProps> = ({ category }) => {
  const links = category.links.filter(
    (item) => item.component === 'dynamo_link'
  ) as Array<DynamoLinkBlok>;

  const imageLinks = category.links.filter(
    (item) => item.component === 'dynamo_image_link'
  ) as Array<DynamoImageLinkBlok>;

  return (
    <ul className={styles['l3-category']}>
      {links.map((item) => (
        <li key={item._uid} className={styles['l3-category__item']}>
          <Anchor
            href={coerceUrl(item.link)!}
            data-testid="header"
            size={'sm'}
            decoration={'hover'}
            prefetch={false}
          >
            {item.title}
          </Anchor>
        </li>
      ))}
      {imageLinks.map((item) => (
        <li key={item._uid} className={styles['l3-category__item']}>
          <DynamoImageLink blok={item} />
        </li>
      ))}
    </ul>
  );
};
