import { FC } from 'react';

import { FasChevronRight } from '@virginmediao2/momentum-icons/icons/fas';

import { Anchor } from '@/components/momentum/components/anchor';
import { Text } from '@/components/momentum/components/text';

import { Icon } from '../icon';
import { TextColor } from '../text/text';
import styles from './image-link.module.scss';

export interface ImageLinkProps {
  title: string;
  linkText: string;
  href: string;
  image: React.ReactNode;
  color: TextColor;
}

export const ImageLink: FC<ImageLinkProps> = ({
  title,
  linkText,
  href,
  image,
  color = 'light',
}) => {
  return (
    <div className={styles['image-link']}>
      <div className={styles['image-link__image']}>
        {!linkText ? (
          <Anchor href={href} expand={true} data-testid="header" decoration='none' isImage={true}>
            {image}
          </Anchor>
        ) : (
          image
        )}
      </div>

      <div className={styles['image-link__content']}>
        {title && (
          <Text color={color} weight='medium'>
            {title}
          </Text>
        )}
        {linkText && (
          <Anchor href={href} data-testid="header" expand={true} decoration='none' isImage={true}>
            <Text as={'span'} color={color} size='T200'>
              {linkText}
              <Icon icon={FasChevronRight} size={'sm'} />
            </Text>
          </Anchor>
        )}
      </div>
    </div>
  );
};
