'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';

import { Anchor } from '@virginmediao2/momentum-nextjs/anchor';
import { Box } from '@virginmediao2/momentum-nextjs/box';
import { Button } from '@virginmediao2/momentum-nextjs/button';
import { Flex } from '@virginmediao2/momentum-nextjs/flex';
import {
  FormInput,
  FormSelect,
  FormTextarea,
} from '@virginmediao2/momentum-nextjs/forms';
import { Text } from '@virginmediao2/momentum-nextjs/text';
import React, {
  FormEvent,
  createContext,
  startTransition,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldErrors, UseFormReturn, useForm } from 'react-hook-form';

import { HorizontalRule } from '@/components/momentum/components/horizontal-rule';
import { StepIndicator } from '@/components/momentum/components/step-indicator';
import { VerticalRhythm } from '@/components/momentum/components/vertical-rhythm';

import { action } from './action';
import styles from './form.module.scss';
import { PHONE_TYPES, TARIFF_TYPES, unlockPhoneSchema } from './schema';

type Step = keyof z.infer<typeof unlockPhoneSchema>;

type ServerActionPayload = [
  state: FormAction,
  dispatch: (formStatus: FormAction, payload: any) => Promise<void>,
  isLoading: boolean,
];

type UnlockFormValue = {
  unlockPhoneForm: UseFormReturn<z.infer<typeof unlockPhoneSchema>>;
  goToForgottenPassword: () => void;
  goBackToStepOne: () => void;
  serverActionPayload: ServerActionPayload;
};

const UnlockPhoneFormContext = createContext<UnlockFormValue>(null as any);

const PayMonthlyforgottenPasswordStep = () => {
  const { unlockPhoneForm, goBackToStepOne } = useContext(
    UnlockPhoneFormContext
  );

  return (
    <VerticalRhythm spaceBetween='lg' verticalRhythm='xs'>
      <Text as='h2' size='T600'>
        Forgotten your password?
      </Text>
      <Text>
        Fill in the details in the spaces below. You&apos;ll have to fill in any
        field marked with a * to continue.
      </Text>
      <FormInput
        id='dateOfLastBill'
        label={<Text>{'The date of your last bill*'}</Text>}
        {...unlockPhoneForm.register(
          'payMonthlyforgottenPassword.dateOfLastBill'
        )}
        error={
          unlockPhoneForm.formState.errors.payMonthlyforgottenPassword
            ?.dateOfLastBill
        }
        type='date'
      />
      <FormInput
        id='amountOfLastBill'
        label={<Text>{'The amount of your last bill*'}</Text>}
        {...unlockPhoneForm.register(
          'payMonthlyforgottenPassword.amountOfLastBill'
        )}
        error={
          unlockPhoneForm.formState.errors.payMonthlyforgottenPassword
            ?.amountOfLastBill
        }
        affix='£'
      />
      <FormInput
        id='tariff'
        label={<Text>{'Tell us the tariff you are on*'}</Text>}
        {...unlockPhoneForm.register('payMonthlyforgottenPassword.tariff')}
        error={
          unlockPhoneForm.formState.errors.payMonthlyforgottenPassword?.tariff
        }
      />
      <Text>
        If you don&apos;t know the name of your tariff, tell us what allowances
        you get.
      </Text>
      <VerticalRhythm verticalRhythm='2xl'>
        <Flex gap='md'>
          <Button
            layout='full-width'
            decoration='outline'
            onClick={() => {
              goBackToStepOne();
            }}
          >
            Home
          </Button>
          <Button layout='full-width'>Next</Button>
        </Flex>
      </VerticalRhythm>
    </VerticalRhythm>
  );
};

const ForgottenPasswordStep = () => {
  const { unlockPhoneForm, goBackToStepOne } = useContext(
    UnlockPhoneFormContext
  );

  return (
    <VerticalRhythm spaceBetween='lg' verticalRhythm='xs'>
      <Text as='h2' size='T600'>
        Forgotten your password?
      </Text>
      <Text>
        Fill in the details in the spaces below. You&apos;ll have to fill in any
        field marked with a * to continue.
      </Text>
      <FormInput
        id='companyName'
        label={<Text>{'Company name (if applicable)'}</Text>}
        {...unlockPhoneForm.register('forgottenPassword.companyName')}
        error={unlockPhoneForm.formState.errors.forgottenPassword?.companyName}
      />
      <FormInput
        id='addressLine'
        label={<Text>{'The first line of your address*'}</Text>}
        {...unlockPhoneForm.register('forgottenPassword.firstLine')}
        error={unlockPhoneForm.formState.errors.forgottenPassword?.firstLine}
      />
      <FormInput
        id='postCode'
        label={<Text>{'Your postcode*'}</Text>}
        {...unlockPhoneForm.register('forgottenPassword.postCode')}
        error={unlockPhoneForm.formState.errors.forgottenPassword?.postCode}
      />
      <FormInput
        id='amountOfLastBill'
        label={<Text>{'The amount of your last bill*'}</Text>}
        {...unlockPhoneForm.register('forgottenPassword.amountOfLastBill')}
        error={
          unlockPhoneForm.formState.errors.forgottenPassword?.amountOfLastBill
        }
        affix='£'
      />
      <FormInput
        id='dateOfLastBill'
        label={<Text>{'The date of your last bill*'}</Text>}
        {...unlockPhoneForm.register('forgottenPassword.dateOfLastBill')}
        error={
          unlockPhoneForm.formState.errors.forgottenPassword?.dateOfLastBill
        }
        type='date'
      />
      <VerticalRhythm verticalRhythm='2xl'>
        <Flex gap='md'>
          <Button
            layout='full-width'
            decoration='outline'
            onClick={() => {
              goBackToStepOne();
            }}
          >
            Home
          </Button>
          <Button layout='full-width'>Next</Button>
        </Flex>
      </VerticalRhythm>
    </VerticalRhythm>
  );
};

const AdditionalInformationStep = () => {
  const { unlockPhoneForm, goBackToStepOne, serverActionPayload } = useContext(
    UnlockPhoneFormContext
  );
  const [, , loading] = serverActionPayload;

  return (
    <VerticalRhythm verticalRhythm='xs' spaceBetween='lg'>
      <Text as='h2' size='T600'>
        Additional information
      </Text>
      <Text>
        Fill in the details in the spaces below. You&apos;ll have to fill in any
        field marked with a * to continue.
      </Text>
      <FormInput
        id='model'
        label={<Text>{'The model of your phone*'}</Text>}
        {...unlockPhoneForm.register('additionalInformation.model')}
        error={unlockPhoneForm.formState.errors.additionalInformation?.model}
      />
      <FormInput
        id='imei'
        label={<Text>{'Your phone IMEI number*'}</Text>}
        {...unlockPhoneForm.register('additionalInformation.imei')}
        error={unlockPhoneForm.formState.errors.additionalInformation?.imei}
      />
      <Text>
        You can find your IMEI number by entering *#06# on your phone&apos;s
        keypad. It should be 15 digits long.
      </Text>
      <FormTextarea
        label={
          <Text>
            {`Type in the mobile number, IMEI number and model of any other phones you want to unlock.`}
          </Text>
        }
        id='additionalImei'
        {...unlockPhoneForm.register('additionalInformation.additionalImei')}
        error={
          unlockPhoneForm.formState.errors.additionalInformation?.additionalImei
        }
        rows={10}
      />
      <VerticalRhythm verticalRhythm='2xl'>
        <Flex gap='md'>
          <Button
            layout='full-width'
            decoration='outline'
            onClick={() => {
              goBackToStepOne();
            }}
          >
            Home
          </Button>
          <Button layout='full-width' disabled={loading}>
            Submit
          </Button>
        </Flex>
      </VerticalRhythm>
    </VerticalRhythm>
  );
};

const DetailsStep = () => {
  const { unlockPhoneForm } = useContext(UnlockPhoneFormContext);

  return (
    <>
      <VerticalRhythm verticalRhythm='xs' spaceBetween='lg'>
        <Text as='h2' size='T600'>
          Your details
        </Text>
        <Text>
          Fill in your details in the spaces below. You&apos;ll have to fill in
          any field marked with a * to continue.
        </Text>
        <FormSelect
          id='tariff'
          label={<Text>{'What kind of tariff are you on?*'}</Text>}
          {...unlockPhoneForm.register('details.tariff')}
          error={unlockPhoneForm.formState.errors.details?.tariff}
        >
          {TARIFF_TYPES.map((option) => (
            <option key={option} label={option} value={option}>
              {option}
            </option>
          ))}
        </FormSelect>
        <FormSelect
          id='phone'
          label={<Text>{'What kind of phone do you have?*'}</Text>}
          {...unlockPhoneForm.register('details.phone')}
          error={unlockPhoneForm.formState.errors.details?.phone}
        >
          {PHONE_TYPES.map((phoneType) => (
            <option key={phoneType} value={phoneType}>
              {phoneType}
            </option>
          ))}
        </FormSelect>
        <FormInput
          id='mobileNumber'
          label={<Text>{'Your mobile number*'}</Text>}
          required
          {...unlockPhoneForm.register('details.mobileNumber')}
          error={unlockPhoneForm.formState.errors.details?.mobileNumber}
        />
        <FormInput
          id='alternativeNumber'
          label={<Text>{'Alternative contact number'}</Text>}
          {...unlockPhoneForm.register('details.alternativeNumber')}
          error={unlockPhoneForm.formState.errors.details?.alternativeNumber}
        />
        <FormInput
          id='firstName'
          label={<Text>{'Your first name*'}</Text>}
          {...unlockPhoneForm.register('details.firstName')}
          error={unlockPhoneForm.formState.errors.details?.firstName}
        />
        <FormInput
          id='lastName'
          label={<Text>{'Your last name*'}</Text>}
          {...unlockPhoneForm.register('details.lastName')}
          error={unlockPhoneForm.formState.errors.details?.lastName}
        />
        <FormInput
          id='email'
          label={<Text>{'Your email address*'}</Text>}
          {...unlockPhoneForm.register('details.email')}
          error={unlockPhoneForm.formState.errors.details?.email}
        />
        <FormInput
          id='confirmEmail'
          label={<Text>{'Confirm your email address*'}</Text>}
          {...unlockPhoneForm.register('details.confirmEmail')}
          error={unlockPhoneForm.formState.errors.details?.confirmEmail}
        />
      </VerticalRhythm>
      <VerticalRhythm verticalRhythm='2xl'>
        <Button
          type='submit'
          layout='full-width'
          className={styles['submit-button-details']}
        >
          Next
        </Button>
      </VerticalRhythm>
    </>
  );
};

const PayAsYouGoSecurityStep = () => {
  const { unlockPhoneForm, goBackToStepOne, goToForgottenPassword } =
    useContext(UnlockPhoneFormContext);

  return (
    <VerticalRhythm spaceBetween='lg' verticalRhythm='xs'>
      <Text as='h2' size='T600'>
        Your security details
      </Text>
      <Text>Enter the characters from your security password.</Text>
      <Text>
        Don&apos;t forget - this is the password you use to contact customer
        services, not the one you use to sign into our website.
      </Text>
      <FormInput
        id='char2'
        label={<Text>{'Character 2'}</Text>}
        {...unlockPhoneForm.register('payAsYouGoSecurity.char2')}
        maxLength={1}
        type='password'
        error={unlockPhoneForm.formState.errors.payAsYouGoSecurity?.char2}
      />
      <FormInput
        id='char3'
        label={<Text>{'Character 3'}</Text>}
        {...unlockPhoneForm.register('payAsYouGoSecurity.char3')}
        maxLength={1}
        type='password'
        error={unlockPhoneForm.formState.errors.payAsYouGoSecurity?.char2}
      />
      <FormInput
        id='airTimeBalance'
        label={<Text>{`What's your current airtime balance?*`}</Text>}
        {...unlockPhoneForm.register('payAsYouGoSecurity.airTimeBalance')}
        error={
          unlockPhoneForm.formState.errors.payAsYouGoSecurity?.airTimeBalance
        }
        affix='£'
      />
      <Text>
        Type in two phone numbers you regularly call from this mobile*
      </Text>
      <FormInput
        id='frequentNumber1'
        label={<Text>{`Frequent number 1*`}</Text>}
        {...unlockPhoneForm.register('payAsYouGoSecurity.frequentNumber1')}
        error={
          unlockPhoneForm.formState.errors.payAsYouGoSecurity?.frequentNumber1
        }
      />
      <FormInput
        id='frequentNumber2'
        label={<Text>{`Frequent number 2*`}</Text>}
        {...unlockPhoneForm.register('payAsYouGoSecurity.frequentNumber2')}
        error={
          unlockPhoneForm.formState.errors.payAsYouGoSecurity?.frequentNumber2
        }
      />
      <Anchor asChild>
        <button
          onClick={() => {
            goToForgottenPassword();
          }}
        >
          I don&apos;t know my password
        </button>
      </Anchor>
      <VerticalRhythm verticalRhythm='2xl'>
        <Flex gap='md'>
          <Button
            layout='full-width'
            decoration='outline'
            onClick={() => {
              goBackToStepOne();
            }}
          >
            Home
          </Button>
          <Button layout='full-width'>Next</Button>
        </Flex>
      </VerticalRhythm>
    </VerticalRhythm>
  );
};

const PayMonthlySecurityStep = () => {
  const { unlockPhoneForm, goBackToStepOne, goToForgottenPassword } =
    useContext(UnlockPhoneFormContext);

  return (
    <VerticalRhythm spaceBetween='lg' verticalRhythm='xs'>
      <Text as='h2' size='T600'>
        Your security details
      </Text>
      <Text>
        Enter the characters from your security password (if you have one).
      </Text>
      <Text>
        Don&apos;t forget - this is the password you use to contact customer
        services, not the one you use to sign into our website.
      </Text>
      <FormInput
        id='char2'
        label={<Text>{'Character 2'}</Text>}
        {...unlockPhoneForm.register('payMonthlySecurity.char2')}
        maxLength={1}
        type='password'
        error={unlockPhoneForm.formState.errors.payMonthlySecurity?.char2}
      />
      <FormInput
        id='char3'
        label={<Text>{'Character 3'}</Text>}
        {...unlockPhoneForm.register('payMonthlySecurity.char3')}
        maxLength={1}
        type='password'
        error={unlockPhoneForm.formState.errors.payMonthlySecurity?.char3}
      />
      <Anchor asChild>
        <button
          onClick={() => {
            goToForgottenPassword();
          }}
        >
          I don&apos;t know my password
        </button>
      </Anchor>
      <VerticalRhythm verticalRhythm='2xl'>
        <Flex gap='md'>
          <Button
            layout='full-width'
            decoration='outline'
            onClick={() => {
              goBackToStepOne();
            }}
          >
            Home
          </Button>
          <Button layout='full-width'>Next</Button>
        </Flex>
      </VerticalRhythm>
    </VerticalRhythm>
  );
};

const BusinessSecurityStep = () => {
  const { unlockPhoneForm, goBackToStepOne, goToForgottenPassword } =
    useContext(UnlockPhoneFormContext);

  return (
    <VerticalRhythm spaceBetween='lg' verticalRhythm='xs'>
      <Text as='h2' size='T600'>
        Your security details
      </Text>
      <Text>
        Fill in the details in the spaces below. You&apos;ll have to fill in any
        field marked with a * to continue.
      </Text>
      <FormInput
        id='companyName'
        label={<Text>{'Company name (if applicable)'}</Text>}
        {...unlockPhoneForm.register('businessSecurity.companyName')}
        error={unlockPhoneForm.formState.errors.businessSecurity?.companyName}
      />
      <FormInput
        id='addressLine'
        label={<Text>{'The first line of your address*'}</Text>}
        {...unlockPhoneForm.register('businessSecurity.firstLine')}
        error={unlockPhoneForm.formState.errors.businessSecurity?.firstLine}
      />
      <FormInput
        id='postCode'
        label={<Text>{'Your postcode*'}</Text>}
        {...unlockPhoneForm.register('businessSecurity.postCode')}
        error={unlockPhoneForm.formState.errors.businessSecurity?.postCode}
      />
      <FormInput
        id='password'
        label={<Text>{'Your security password*'}</Text>}
        {...unlockPhoneForm.register('businessSecurity.password')}
        error={unlockPhoneForm.formState.errors.businessSecurity?.password}
        type='password'
      />
      <Text>
        Don&apos;t forget - this is the password you use to contact customer
        services, not the one you use to sign into our website.
      </Text>
      <Anchor asChild>
        <button
          onClick={() => {
            goToForgottenPassword();
          }}
        >
          I don&apos;t know my password
        </button>
      </Anchor>
      <VerticalRhythm verticalRhythm='2xl'>
        <Flex gap='md'>
          <Button
            layout='full-width'
            decoration='outline'
            onClick={() => {
              goBackToStepOne();
            }}
          >
            Home
          </Button>
          <Button layout='full-width'>Next</Button>
        </Flex>
      </VerticalRhythm>
    </VerticalRhythm>
  );
};

const stepToComponentMap: Record<Step, React.FunctionComponent> = {
  details: DetailsStep,
  payMonthlyforgottenPassword: PayMonthlyforgottenPasswordStep,
  businessSecurity: BusinessSecurityStep,
  forgottenPassword: ForgottenPasswordStep,
  payAsYouGoSecurity: PayAsYouGoSecurityStep,
  payMonthlySecurity: PayMonthlySecurityStep,
  additionalInformation: AdditionalInformationStep,
};

type Form = z.infer<typeof unlockPhoneSchema>;

export type FormAction = {
  errors?: FieldErrors<Form>;
  success?: boolean;
  loading: boolean;
};

const stepToLabelMap: Record<Step, string> = {
  details: 'Step 1 of 3: Details',
  businessSecurity: 'Step 2 of 3: Security',
  forgottenPassword: 'Step 2 of 3: Security',
  payAsYouGoSecurity: 'Step 2 of 3: Security',
  payMonthlySecurity: 'Step 2 of 3: Security',
  payMonthlyforgottenPassword: 'Step 2 of 3: Security',
  additionalInformation: 'Step 3 of 3: Additional information',
};

export const UnlockPhoneForm = () => {
  const [formStatus, setFormStatus] = useState<FormAction>({
    success: false,
    loading: false,
    errors: undefined,
  });

  const actionHandler = async (formStatus: FormAction, payload: any) => {
    startTransition(async () => {
      setFormStatus((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });

      const result = await action(formStatus, payload);

      setFormStatus(result);

      return undefined;
    });
  };

  const unlockPhoneForm = useForm<Form>({
    resolver: zodResolver(unlockPhoneSchema),
    criteriaMode: 'all',
    errors: formStatus.errors,
  });

  const [formStep, setFormStep] = useState<Step>('details');

  const journey = useRef<Array<Step>>(['details']);

  let stepIndexForIndicator = 1;

  if (formStep === 'additionalInformation') {
    stepIndexForIndicator = 2;
  }

  if (formStep === 'details') {
    stepIndexForIndicator = 0;
  }

  const goBackToStepOne = useCallback(() => {
    journey.current = ['details'];

    setFormStep('details');

    unlockPhoneForm.trigger('details');
  }, [setFormStep, unlockPhoneForm]);

  const goToForgottenPassword = useCallback(() => {
    journey.current.pop();

    if (formStep === 'businessSecurity') {
      journey.current.push('forgottenPassword');

      setFormStep('forgottenPassword');
    } else if (
      formStep === 'payAsYouGoSecurity' ||
      formStep === 'payMonthlySecurity'
    ) {
      journey.current.push('payMonthlyforgottenPassword');

      setFormStep('payMonthlyforgottenPassword');
    }
  }, [formStep, setFormStep]);

  const FormStepComponent = stepToComponentMap[formStep];

  const bag = useMemo<UnlockFormValue>(() => {
    return {
      unlockPhoneForm,
      goBackToStepOne,
      goToForgottenPassword,
      serverActionPayload: [formStatus, actionHandler, formStatus.loading],
    };
  }, [
    unlockPhoneForm,
    goBackToStepOne,
    goToForgottenPassword,
    actionHandler,
    formStatus,
  ]);

  const extractDataFromSteps = useCallback(
    (formData: FormData, step: Step) => {
      const valuesPerStep = unlockPhoneForm.getValues(step);

      for (let keyInCurrentStep in valuesPerStep) {
        // @ts-expect-error -- keyInCurrentStep is the keyof valuesPerStep
        const value = valuesPerStep[keyInCurrentStep];
        formData.append(`${step}.${keyInCurrentStep}`, value);
      }
    },
    [unlockPhoneForm]
  );

  const handleServerSubmission = useCallback(() => {
    unlockPhoneForm.trigger(formStep);

    let isFormValid = true;

    const formData = new FormData();

    for (const step of journey.current) {
      const valid = unlockPhoneSchema.shape[step].safeParse(
        unlockPhoneForm.getValues()[step]
      );

      isFormValid = valid.success;

      extractDataFromSteps(formData, step);
    }

    if (isFormValid) {
      actionHandler(formStatus, formData);
    }
  }, [unlockPhoneForm, extractDataFromSteps, actionHandler, formStep]);

  const handleStepChange = useCallback(
    (step: Step) => {
      if (step in unlockPhoneForm.formState.dirtyFields) {
        unlockPhoneForm.trigger(step);
      }

      journey.current.push(step);

      setFormStep(step);
    },
    [setFormStep, unlockPhoneForm]
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const valid = unlockPhoneSchema.shape[formStep].safeParse(
        unlockPhoneForm.getValues()[formStep]
      );

      if (!valid.success) {
        return unlockPhoneForm.trigger(formStep);
      }

      const tariff = unlockPhoneForm.getValues('details.tariff');

      if (formStep === 'details' && tariff === 'Business') {
        handleStepChange('businessSecurity');
      }

      if (formStep === 'details' && tariff === 'Pay Monthly') {
        handleStepChange('payMonthlySecurity');
      }

      if (formStep === 'details' && tariff === 'Pay As You Go') {
        handleStepChange('payAsYouGoSecurity');
      }

      if (
        formStep === 'payAsYouGoSecurity' ||
        formStep === 'payMonthlySecurity' ||
        formStep === 'businessSecurity' ||
        formStep === 'forgottenPassword' ||
        formStep === 'payMonthlyforgottenPassword'
      ) {
        handleStepChange('additionalInformation');
      }

      if (formStep === 'additionalInformation') {
        handleServerSubmission();
      }
    },
    [handleStepChange, handleServerSubmission, formStep, unlockPhoneForm]
  );

  if (formStatus.success) {
    return (
      <VerticalRhythm verticalRhythm='xs' spaceBetween='lg'>
        <Text as='h2' size='T600'>
          Form submitted
        </Text>
        <Text>
          Your request has been submitted to our customer service team.
          We&apos;ll get back to you by email in the next 28 days to let you
          know what you need to do next.
        </Text>
        <Text>
          If you&apos;ve asked for your iPhone to be unlocked, we&apos;ll text
          you in the next 14 days.
        </Text>
      </VerticalRhythm>
    );
  }

  return (
    <UnlockPhoneFormContext.Provider value={bag}>
      <form onSubmit={handleSubmit}>
        <VerticalRhythm spaceBetween='lg'>
          <Text as='h2' size='T600'>
            Unlocking an O2 mobile to use on a different network
          </Text>
          <Box padding='sm' border={false}>
            <StepIndicator
              numberOfSteps={2}
              activeStep={stepIndexForIndicator}
              label={stepToLabelMap[formStep]}
            />
          </Box>
          <HorizontalRule spacing='sm' />
        </VerticalRhythm>
        <FormStepComponent />
      </form>
    </UnlockPhoneFormContext.Provider>
  );
};
