import { isRichTextEmpty } from '@storyblok/js';
import clsx from 'clsx';

import { Content } from '@virginmediao2/momentum-nextjs/content';

import { DescriptionList } from '@/components/momentum/components/description-list';
import { HorizontalRule } from '@/components/momentum/components/horizontal-rule';
import { Text } from '@/components/momentum/components/text';
import { IStoryblokComponent } from '@/components/storyblok/renderer';
import { RenderRichText } from '@/components/storyblok/utils/render-richtext';

import { useRates } from '../hooks/use-rates';
import { IntlCallRatesBlok } from '../types';
import styles from './CountryRates.module.css';

export const PayAsYouGo = ({
  blok,
  StoryblokComponent,
}: {
  blok: IntlCallRatesBlok;
  StoryblokComponent: IStoryblokComponent;
}) => {
  const { payg_inbound, payg_outbound, payg_data, payg_sms } = useRates(blok);

  const filteredItems = [
    { term: 'Making Calls', description: payg_outbound },
    { term: 'Receiving Calls', description: payg_inbound },
    { term: 'Sending Texts', description: payg_sms },
    { term: 'Using Data', description: payg_data },
  ].filter((item) => item.description.toLowerCase() !== 'unknown');

  return (
    <>
      <div
        className={clsx(
          styles['country__rates'],
          styles['country__rates--payg']
        )}
      >
        {blok.payg_zone_info && !isRichTextEmpty(blok.payg_zone_info) && (
          <>
            <Content>
              <RenderRichText
                document={blok.payg_zone_info}
                StoryblokComponent={StoryblokComponent}
              />
            </Content>
            <HorizontalRule spacing={'md'} />
          </>
        )}

        {blok.payg_message && (
          <div className={styles['country__rates--paym-msg']}>
            <Text>{blok.payg_message}</Text>
          </div>
        )}

        <div className={styles['country__rates-table']}>
          <div className={styles['country__rates-tablehead']}>
            <Text>Standard Rates</Text>
          </div>
          <DescriptionList ratio={'1:1'} list={filteredItems} />
        </div>
      </div>
    </>
  );
};
