import {
  Merchant,
  Service,
  ServiceCharge,
  ServiceType,
  ShortCode,
} from './interfaces';

export const CONTENT = {
  CHARGE_TITLE: 'is a Charge to Mobile service',
  PREM_TEXT_TITLE: 'is a premium rate text',
  PREM_TEXT_LABEL: 'Find out more on premium texts.',
  PREM_NUM_TITLE: 'is a premium number',
};

/*
Merchant = YC_Merchants.csv
Shortcode = ESME_Shortcode_detail.csv
*/

export const isMerchant = (value: string): boolean => {
  return !isShortCode(value);
};

export const isShortCode = (value: string): boolean => {
  const normalised = value.replace(/\s+/g, '');
  return !isNaN(parseFloat(normalised)) && isFinite(Number(normalised));
};

const normalisedNumber = (value: string): string => {
  return value.replace(/\s+/g, '');
};

// 0843, 0844, 0845, 0870, 0871, 0872 numbers (any length after prefix)
const isNonGeographicNumber = (value: string): boolean => {
  return /^(0843|0844|0845|0870|0871|0872)\d*$/.test(normalisedNumber(value));
};

// Directory enquiry numbers starting with 118
const isDirectoryEnquiries = (value: string): boolean => {
  return /^118\d*$/.test(normalisedNumber(value));
};

// 8-digit number starting with 3
const isStandardMobileCharge = (value: string): boolean => {
  return /^3\d{7}$/.test(normalisedNumber(value));
};

// Premium rate number starting with 09 (any length)
const isPremiumNumber = (value: string): boolean => {
  return /^09\d+$/.test(normalisedNumber(value));
};

export const isRecognisedCharge = (value: string): boolean => {
  // Check if it matches any of the premium charge patterns
  return (
    isPremiumNumber(value) ||
    isStandardMobileCharge(value) ||
    isNonGeographicNumber(value) ||
    isDirectoryEnquiries(value)
  );
};

const isPremiumText = (value: string): boolean => {
  // Check if it matches any of the premium text patterns
  return (
    // 5-6 digits starting with 6, 7, or 8
    /^[678]\d{4,5}$/.test(normalisedNumber(value))
  );
};

export const getLabelForValue = (value: string) => {
  const premiumNumber = isPremiumNumber(value);
  const premiumText = isPremiumText(value);
  const mobileCharge = isStandardMobileCharge(value);
  const nonGeo = isNonGeographicNumber(value);
  const dirEnq = isDirectoryEnquiries(value);

  if (premiumText) {
    return `${value} ${CONTENT.PREM_TEXT_TITLE}`;
  }
  if (mobileCharge) {
    return `${value} ${CONTENT.CHARGE_TITLE}`;
  }
  if (nonGeo) {
    return `${value} ${CONTENT.PREM_NUM_TITLE}`;
  }
  if (dirEnq) {
    return `${value} ${CONTENT.PREM_NUM_TITLE}`;
  }
  if (premiumNumber) {
    return `${value} ${CONTENT.PREM_NUM_TITLE}`;
  }

  return `${value} ${CONTENT.CHARGE_TITLE}`;
};

export const getDescForValue = (value: string): ServiceType => {
  const premiumNumber = isPremiumNumber(value);
  const premiumText = isPremiumText(value);
  const directoryEnquires = isDirectoryEnquiries(value);
  const nonGeographic = isNonGeographicNumber(value);
  const mobileCharge = isStandardMobileCharge(value);

  if (premiumText) {
    return Service.PremiumText;
  }
  if (directoryEnquires) {
    return Service.Directory;
  }
  if (nonGeographic) {
    return Service.NonGeographicNumber;
  }
  if (mobileCharge) {
    return Service.StandardCharge;
  }
  if (premiumNumber) {
    return Service.PremiumNumber;
  }

  return Service.StandardCharge;
};

const getFromList = <T extends Record<string, any>>(
  searchValue: string,
  list: T[],
  matchProp: keyof T,
  updateProp: keyof T,
  parseDateFn: (v: string) => Date | undefined
): T | null => {
  const matching = list.filter(
    (i) => i[matchProp]?.toLowerCase() === searchValue.trim().toLowerCase()
  );

  if (matching.length === 0) {
    return null;
  }

  if (matching.length === 1) {
    return matching[0];
  }

  matching.sort((a, b) => {
    const dateB = parseDateFn(b[updateProp]);
    const dateA = parseDateFn(a[updateProp]);
    if (dateB && dateA) {
      return dateB.getTime() - dateA.getTime();
    }

    if (!dateA && dateB) return 1;
    if (!dateB && dateA) return -1;

    return 0;
  });

  // Return the most recent
  return matching[0];
};

/*
date string provided in csv file is not a valid format so this is a custom function that parses
*/
const parseCustomDateFormat = (
  dateString: string,
  merchant: boolean
): Date | undefined => {
  const dateTrimmed = dateString.trim();

  if (!dateTrimmed) {
    return;
  }

  if (merchant) {
    // 'D.M.YY'
    const [day, month, year] = dateTrimmed.split('.').map(Number);
    return new Date(year, month - 1, day, 0, 0, 0);
  }
  // 'YY-M-D H.M.S'
  const [datePart, timePart] = dateTrimmed.split(' ');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hours, minutes, seconds] = timePart.split('.').map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds);
};

const parseMerchantDate = (dateStr: string) =>
  parseCustomDateFormat(dateStr, true);

const parseShortCodeDate = (dateStr: string) =>
  parseCustomDateFormat(dateStr, false);

export const getMatchingMerchant = (
  search: string,
  merchants: Merchant[]
): Merchant | null => {
  return getFromList(
    search,
    merchants,
    'YC',
    'Date last updated',
    parseMerchantDate
  );
};

export const getMatchingShortCode = (
  search: string,
  shortcodes: ShortCode[]
): ShortCode | null => {
  return getFromList(
    search,
    shortcodes,
    'shortcode',
    'last_updated',
    parseShortCodeDate
  );
};

export const getChargeFromMerchants = (
  search: string,
  merchants: Merchant[]
): ServiceCharge | null => {
  const merchant = getMatchingMerchant(search, merchants);
  if (merchant) {
    const reference = merchant.YC || search;
    return {
      title: `${reference} ${CONTENT.CHARGE_TITLE}`,
      descriptionType: Service.StandardCharge,
      reference,
      service: merchant['Merchant name'],
      category: merchant['Content category i.e Game/Betting'],
      contactNo: merchant['Customer service number'],
      stopContent: merchant['How do you stop the service'] || '',
    };
  }

  return null;
};

export const getIsTop100 = (sc: ShortCode) => {
  return (
    sc.Top100?.toLowerCase().trim().startsWith('y') ||
    sc.shortcode?.startsWith('70')
  );
};

export const getContentForValue = (value: string): ServiceCharge => {
  return {
    title: getLabelForValue(value),
    descriptionType: getDescForValue(value),
    reference: value,
  };
};

const getShortCodeService = (
  shortCode?: string,
  service_or_product?: string,
  type_of_service?: string
) => {
  const shortcodeTimmed = shortCode?.trim();
  const serviceOrProduct = service_or_product?.trim();
  const typeOfService = type_of_service?.trim();
  const hasServiceOrProduct = serviceOrProduct && serviceOrProduct != '.';
  const hasTypeOfService = typeOfService && typeOfService != '.';

  if (
    shortcodeTimmed &&
    shortcodeTimmed.startsWith('70') &&
    hasServiceOrProduct
  ) {
    return serviceOrProduct;
  }
  if (hasTypeOfService) {
    return typeOfService;
  }
};

export const getChargeFromShortCodes = (
  search: string,
  shortcodes: ShortCode[]
): ServiceCharge | null => {
  const shortCode = getMatchingShortCode(search, shortcodes);
  const isCharge = isRecognisedCharge(search);
  const premiumText = isPremiumText(search);

  if (shortCode) {
    const reference = shortCode.shortcode || search;
    const service = getShortCodeService(
      shortCode.shortcode,
      shortCode.service_or_product,
      shortCode.type_of_service
    );
    return {
      ...getContentForValue(reference),
      service,
      category: shortCode.content_provider,
      contactNo: shortCode.cstmr_care_cntct_nmbr,
    };
  }

  if (premiumText) {
    return {
      ...getContentForValue(search),
      service: 'Premium Text',
      category: 'Not available',
      contactNo: 'Not available',
    };
  }

  if (isCharge) {
    return {
      ...getContentForValue(search),
    };
  }

  return null;
};
