'use client';

import { Box } from '@virginmediao2/momentum-nextjs/box';
import { Card } from '@virginmediao2/momentum-nextjs/card';
import { Flex } from '@virginmediao2/momentum-nextjs/flex';
import { FlexBlock } from '@virginmediao2/momentum-nextjs/flex-block';
import { Icon } from '@virginmediao2/momentum-nextjs/icon';
import Image from 'next/image';
import {
  Children,
  ComponentProps,
  FC,
  ReactNode,
  useEffect,
  useId,
  useState,
} from 'react';

import { FarXmark } from '@virginmediao2/momentum-icons/icons/far';
import {
  FasChevronRight,
  FasTag,
  FasTimer,
} from '@virginmediao2/momentum-icons/icons/fas';

import { displayTimeDifference } from '@/lib/utils/date-formatter';

import { Anchor } from '../anchor';
import { Badge, BadgeProps } from '../badge';
import { Modal, ModalBody, ModalCloseButton, ModalTitle } from '../modal';
import { ModalProvider, useModal } from '../modal/modal.context';
import { Text } from '../text';
import { Video } from '../video';
import { Voucher } from '../voucher';
import styles from './offer-card.module.scss';

export interface OfferCardProps extends Omit<ComponentProps<'div'>, 'title'> {
  image?: {
    src: string;
    alt: string;
  };
  video?: {
    // Adjust the video prop to match MediaBannerVideo's expected props if necessary.
    src: string;
    title?: string;
  };
  text: ReactNode;
  badges?: ReactNode;
  ctas?: React.ReactNode;
  countdownBadge?: {
    start: string;
    end: string;
  };
  applyVoucher?: boolean;
  cardLevelLink?: string;
  useCardLevelLink?: boolean;
  countDownBadgeVariant?: BadgeProps['variant'];
  isVerticalProductCard?: boolean;
  verticalProductCardTitle?: string;
  verticalProductCardSubtitle?: string;
  verticalProductCardDescription?: string;
  verticalProductCardPrice?: string;
  verticalProductCardDescriptionPostPrice?: string;
  voucherCode?: string;
  voucherText?: string;
  voucherOfferUrl?: string;
  badgeVariant?: string; // Add variant prop
  clickTracking?: string;
  isBanner?: boolean; // Add isBanner prop
  cardLabel?: string;
}

const CardContainer = (props: {
  children: ReactNode;
  applyVoucher?: boolean;
  alt?: string;
  isBanner?: boolean;
  className?: string;
}) => {
  const uniqueId = useId();
  const { open } = useModal();

  return (
    <Card
      tabIndex={0}
      onClick={() => {
        if (props.applyVoucher) {
          open();
        }
      }}
      className={`${styles['card']} ${props.isBanner ? styles['card__banner'] : ''}`}
      data-testid='offer-card'
      aria-labelledby={`offer-card-${uniqueId}`}
    >
      {props.children}
      <span
        id={`offer-card-${uniqueId}`}
        className={styles['visually-hidden']}
      >{`offer card ${props?.alt}`}</span>
    </Card>
  );
};

export const OfferCard: FC<OfferCardProps> = ({
  image,
  video,
  badges,
  ctas,
  countdownBadge,
  applyVoucher,
  cardLevelLink,
  countDownBadgeVariant,
  text,
  isVerticalProductCard,
  verticalProductCardTitle,
  verticalProductCardSubtitle,
  verticalProductCardDescription,
  verticalProductCardPrice,
  verticalProductCardDescriptionPostPrice,
  voucherCode,
  voucherText,
  voucherOfferUrl,
  useCardLevelLink,
  clickTracking,
}) => {
  const src = image?.src || ''; // Use fallback values
  const alt = image?.alt || 'Offer image';

  const [countdownText, setCountdownText] = useState<string>('');

  useEffect(() => {
    if (countdownBadge) {
      const updateCountdown = () => {
        const text = displayTimeDifference(
          countdownBadge.start,
          countdownBadge.end
        );
        setCountdownText(text);
      };

      updateCountdown();

      const intervalId = setInterval(updateCountdown, 1000);

      return () => clearInterval(intervalId);
    }
  }, [countdownBadge]);

  const offerHasBadges =
    countdownBadge ||
    (applyVoucher &&
      typeof voucherCode === 'string' &&
      voucherCode.trim() !== '') ||
    Children.count(badges) > 0;

  let derivedBadgesToRender: ReactNode = badges;

  if (
    applyVoucher &&
    typeof voucherCode === 'string' &&
    voucherCode.trim() !== ''
  ) {
    derivedBadgesToRender = (
      <Badge
        data-testid='voucher-badge'
        key={'countdown'}
        title={voucherCode || ''}
        outline={false}
        variant='offer'
        showIcon={true}
        icon={FasTag}
        rounded={true}
      />
    );
  }

  if (countdownBadge) {
    derivedBadgesToRender = (
      <Badge
        data-testid='countdown-badge'
        key={'countdown'}
        title={countdownText}
        outline={false}
        variant={countDownBadgeVariant}
        showIcon={true}
        icon={FasTimer}
        rounded={true}
      />
    );
  }

  return (
    <ModalProvider data-testid='offer-card-modal'>
      <CardContainer applyVoucher={applyVoucher} alt={alt}>
        <Box className={styles['card__image']} border={false}>
          {image && (
            <Image
              draggable={false}
              data-testid='offer-card-img'
              src={src}
              alt={alt}
              fill={true}
              style={{ objectFit: 'cover' }}
              className={styles['card__image--img']}
            />
          )}
          {video && (
            <Video
              data-testid='card__video--video'
              autoPlay
              controls={false}
              preLoad
              loop
              muted
              playsInline
              createdAt={new Date().toISOString()}
              title={video.title || ''}
              description={video.title || ''}
              src={video.src}
              thumbnailUrl={''}
            />
          )}
        </Box>
        <FlexBlock
          gap='sm'
          direction='column'
          padding='md'
          className={styles['card__content']}
        >
          {offerHasBadges && (
            <FlexBlock gap='sm' className={styles['card__badges']}>
              {derivedBadgesToRender}
            </FlexBlock>
          )}
          {isVerticalProductCard && (
            <div>
              {/* {text} */}
              {verticalProductCardTitle && (
                <Text size='T300' weight='bold' color='inherit'>
                  {verticalProductCardTitle}
                </Text>
              )}
              {verticalProductCardSubtitle && (
                <Text
                  size='T200'
                  lineHeight='T400'
                  weight='regular'
                  color='inherit'
                  className={styles['card__vertical-product-card-subtitle']}
                >
                  {verticalProductCardSubtitle}
                </Text>
              )}
              <hr className={styles['card__hr']} />
              {verticalProductCardDescription && (
                <Text size='T300' weight='regular' color='inherit'>
                  {verticalProductCardDescription}
                </Text>
              )}
              {verticalProductCardPrice && (
                <Text size='T400' weight='bold' color='inherit'>
                  {verticalProductCardPrice}
                </Text>
              )}
              {verticalProductCardDescriptionPostPrice && (
                <Text size='T300' weight='regular' color='inherit'>
                  {verticalProductCardDescriptionPostPrice}
                </Text>
              )}
              {useCardLevelLink && cardLevelLink && !applyVoucher && (
                <Text size='T300' weight='bold' color='inherit'>
                  <Anchor
                    clickTracking={clickTracking}
                    data-testid='cardLevelLink'
                    expand
                    href={cardLevelLink!}
                    decoration='none'
                    draggable={false}
                    aria-label='Offer Card Link'
                  >
                    <Icon icon={FasChevronRight} />
                  </Anchor>
                </Text>
              )}
            </div>
          )}
          {!isVerticalProductCard && (
            <Flex justify='between'>
              {text}
              {useCardLevelLink && cardLevelLink && !applyVoucher && (
                <Text size='T300' weight='bold' color='inherit'>
                  <Anchor
                    clickTracking={clickTracking}
                    data-testid='cardLevelLink'
                    expand
                    href={cardLevelLink!}
                    decoration='none'
                    draggable={false}
                    aria-label='Offer Card Link'
                  >
                    <Icon icon={FasChevronRight} />
                  </Anchor>
                </Text>
              )}
            </Flex>
          )}
          {!useCardLevelLink && (
            <Flex direction='column' gap='md' className={styles['card__ctas']}>
              {ctas}
            </Flex>
          )}
        </FlexBlock>
      </CardContainer>
      <Modal>
        <ModalTitle>
          <FlexBlock padding='md' align='center'>
            Discount Code{' '}
            <ModalCloseButton>
              <Icon icon={FarXmark} />
            </ModalCloseButton>
          </FlexBlock>
        </ModalTitle>
        <ModalBody>
          <Voucher
            vouchercode={voucherCode}
            vouchertext={voucherText}
            voucherbuttontext={'Go to offer'}
            buttonClickUrl={voucherOfferUrl}
          />
        </ModalBody>
      </Modal>
    </ModalProvider>
  );
};
