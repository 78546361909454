'use client';

import Image from 'next/image';
import Script from 'next/script';
import { FC } from 'react';

export interface SpatialBuzzProps {}

export const SpatialBuzz: FC<SpatialBuzzProps> = () => {
  const handleImageLoad = () => {
    if (
      typeof window !== 'undefined' &&
      typeof window.initSbCoverageMap === 'function'
    ) {
      window.initSbCoverageMap();
    }
  };

  return (
    <>
      <Script
        type='text/javascript'
        src='https://o2-uk.spatialbuzz.net/cust/68AA7B45/lib/bootstrap-core.js'
        strategy='afterInteractive'
      />
      <Script
        type='text/javascript'
        src='https://o2-uk.spatialbuzz.net/cust/68AA7B45/hdcoverage/init/bootstrap-hdcv2-9A7459E6-iframe-init.js'
        strategy='afterInteractive'
      />
      <Script
        type='text/javascript'
        src='https://o2-uk.spatialbuzz.net/cust/68AA7B45/hdcoverage/js/bootstrap-hdcoverage-hdcv2-iframe.js'
        strategy='afterInteractive'
      />

      <div id='sbuzz_content'>
        <Image
          src='https://o2-uk.spatialbuzz.net/cust/68AA7B45/images/onload.gif'
          onLoad={handleImageLoad}
          alt='SpatialBuzz'
          width={300}
          height={300}
        />
      </div>
    </>
  );
};
