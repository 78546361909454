'use client';

import { z } from 'zod';

import { Box } from '@virginmediao2/momentum-nextjs/box';
import { Flex } from '@virginmediao2/momentum-nextjs/flex';
import { FlexBlock } from '@virginmediao2/momentum-nextjs/flex-block';
import { FormInput } from '@virginmediao2/momentum-nextjs/forms';
import { ShowMore } from '@virginmediao2/momentum-nextjs/show-more';
import { Text } from '@virginmediao2/momentum-nextjs/text';
import { FC, ReactNode, useCallback, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { getCsv } from '@/lib/get-csv';

import { Merchant, Service, ServiceCharge, ShortCode } from './interfaces';
import styles from './premium-service-checker.module.scss';
import {
  getChargeFromMerchants,
  getChargeFromShortCodes,
  isShortCode,
} from './utils';

export const premiumServiceCheckerSchema = z.object({
  search: z.string(),
});

export interface PremiumServiceCheckerProps {
  input_label: ReactNode;
  placeholder: string;
  submit_label: string;
  shortCodeCsvUrl: string;
  merchantsCsvUrl: string;
  standard_charge_info: ReactNode;
  premium_text_info: ReactNode;
  premium_number_info: ReactNode;
  directory_number_info: ReactNode;
  non_geo_number_info: ReactNode;
  no_results_info: ReactNode;
}

export const PremiumServiceChecker: FC<PremiumServiceCheckerProps> = (
  props
) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<z.infer<typeof premiumServiceCheckerSchema>>();

  const [selectedSearchSubmitted, setSearchSubmitted] = useState<string | null>(
    null
  );
  const [selectedServiceCharge, setServiceCharge] =
    useState<null | ServiceCharge>(null);

  const shortCodeCache = useRef<ShortCode[] | null>(null);
  const merchantCache = useRef<Merchant[] | null>(null);

  const renderInformationForType = (charge: ServiceCharge) => {
    switch (charge.descriptionType) {
      case Service.Directory:
        return props.directory_number_info;
      case Service.NonGeographicNumber:
        return props.non_geo_number_info;
      case Service.PremiumNumber:
        return props.premium_number_info;
      case Service.PremiumText:
        return props.premium_text_info;
      case Service.StandardCharge:
        return props.standard_charge_info;
      default:
        return '';
    }
  };

  /*
  Fetch shortcode csv and cache
  */
  const fetchShortCodes = useCallback(async () => {
    if (!shortCodeCache.current) {
      const shortcodes = await getCsv<ShortCode>(
        props.shortCodeCsvUrl,
        'shortcode'
      );
      shortCodeCache.current = shortcodes.list;
    }
    return shortCodeCache.current!;
  }, [props.shortCodeCsvUrl]);

  /*
  Fetch merchants csv and cache
  */
  const fetchMerchants = useCallback(async () => {
    if (!merchantCache.current) {
      const merchants = await getCsv<Merchant>(props.merchantsCsvUrl, 'YC');
      merchantCache.current = merchants.list;
    }
    return merchantCache.current!;
  }, [props.merchantsCsvUrl]);

  /*
  Submit form and find item in data (depending on search value)
  */
  const onSubmit: SubmitHandler<z.infer<typeof premiumServiceCheckerSchema>> =
    useCallback(
      async (formValues) => {
        setServiceCharge(null);
        setSearchSubmitted(null);

        const { search } = formValues;

        // if search is a number then search in the shortcodes
        if (isShortCode(search)) {
          const shortcodes = await fetchShortCodes();
          const foundSc = getChargeFromShortCodes(search, shortcodes);
          setServiceCharge(foundSc);
        } else {
          // else search merchants for string
          const merchants = await fetchMerchants();
          const foundMerchant = getChargeFromMerchants(search, merchants);
          setServiceCharge(foundMerchant);
        }
        setSearchSubmitted(formValues.search);
      },
      [setServiceCharge, setSearchSubmitted, fetchMerchants, fetchShortCodes]
    );

  return (
    <>
      <Box border={false} padding='md xs'>
        <form autoComplete={'off'} onSubmit={handleSubmit(onSubmit)}>
          <Flex gap='md' grow={1}>
            <div className={styles['input-container']}>
              <FormInput
                {...register('search', { required: true, minLength: 3 })}
                id='search'
                hideLabels={true}
                label={props.input_label}
                placeholder={props.placeholder}
              />
            </div>
            <div className={styles['submit-button-container']}>
              <FormInput
                data-testid='search-button'
                id='find'
                name='find'
                type='submit'
                disabled={isSubmitting || !isValid}
                value={props.submit_label}
              />
            </div>
          </Flex>
        </form>
      </Box>
      {selectedSearchSubmitted && (
        <>
          {selectedServiceCharge ? (
            <>
              <Text size={'T300'} weight={'bold'} marginBottom={true}>
                {selectedServiceCharge.title}
              </Text>
              {(selectedServiceCharge.service ||
                selectedServiceCharge.category ||
                selectedServiceCharge.contactNo) && (
                <dl>
                  <FlexBlock gap='sm' align='start' justify='between'>
                    <div>
                      <dd>
                        <Text size={'T300'} weight={'regular'}>
                          Service
                        </Text>
                      </dd>
                      <dt>
                        <Text size={'T300'} weight={'bold'} marginBottom={true}>
                          {selectedServiceCharge.service}
                        </Text>
                      </dt>
                    </div>
                    <div>
                      <dd>
                        <Text size={'T300'} weight={'regular'}>
                          Provider/Category
                        </Text>
                      </dd>
                      <dt>
                        <Text size={'T300'} weight={'bold'} marginBottom={true}>
                          {selectedServiceCharge.category}
                        </Text>
                      </dt>
                    </div>
                    <div>
                      <dd>
                        <Text size={'T300'} weight={'regular'}>
                          Contact Number
                        </Text>
                      </dd>
                      <dt>
                        <Text size={'T300'} weight={'bold'} marginBottom={true}>
                          {selectedServiceCharge.contactNo}
                        </Text>
                      </dt>
                    </div>
                  </FlexBlock>
                </dl>
              )}

              <div>{renderInformationForType(selectedServiceCharge)}</div>

              {selectedServiceCharge.stopContent && (
                <ShowMore title='How to stop this service'>
                  <Text size={'T200'} weight={'regular'}>
                    {selectedServiceCharge.stopContent}
                  </Text>
                </ShowMore>
              )}
            </>
          ) : (
            <>{props.no_results_info}</>
          )}
        </>
      )}
    </>
  );
};
