import { ISbComponentType } from 'storyblok-js-client';

import Image from 'next/image';
import { FC } from 'react';

import { ImageLink } from '@/components/momentum/components/image-link';
import { TextColor } from '@/components/momentum/components/text/text';
import { BLOK } from '@/components/storyblok/bloks';
import { RelLink } from '@/components/storyblok/types/rel-link';
import { coerceImage } from '@/components/storyblok/utils/coerce-image';
import { coerceUrl } from '@/components/storyblok/utils/coerce-url';

import { ISbAsset } from '../../types/storyblok.interface';

export type DynamoImageLinkBlok = ISbComponentType<BLOK.DYNAMO_IMAGE_LINK> & {
  image: ISbAsset;
  link: RelLink;
  title: string;
  link_text: string;
  color: TextColor;
};

export interface DynamoImageLinkProps {
  blok: DynamoImageLinkBlok;
}

export const DynamoImageLink: FC<DynamoImageLinkProps> = ({ blok }) => {
  const href = coerceUrl(blok.link) || '/';
  const image = coerceImage(blok.image);
  if (!image) return null;
  return (
    <ImageLink
      href={href}
      title={blok.title}
      linkText={blok.link_text}
      color={blok.color}
      image={
        <Image
          alt={image.alt}
          src={image.src}
          width={image.width}
          height={image.height}
          unoptimized={image.unoptimized}
        />
      }
    />
  );
};
