'use client';

import clsx from 'clsx';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
  Autoplay,
  EffectCoverflow,
  EffectFade,
  Navigation,
  Pagination,
} from 'swiper/modules';

import { FC, ReactNode, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './media-carousel.module.scss';
import { MediaCarouselProps } from './types';

export const MediaCarousel: FC<MediaCarouselProps> = ({
  isPagination,
  isNavigation,
  loop,
  autoplay,
  effect,
  slides,
  children,
}) => {
  const initialTheme = slides[0]?.dark ? 'dark' : 'light';
  const [theme, setTheme] = useState<'dark' | 'light'>(initialTheme);

  const onInit = (swiper: any) => {
    swiper.on('transitionStart', (slide: any) =>
      setTheme(slides[slide.realIndex]?.dark ? 'dark' : 'light')
    );
  };

  const pagination = () => {
    const bulletClass = clsx({
      [styles[`media-carousel__pagination__bullet`]]: true,
    });

    const bulletActiveClass = clsx({
      [styles[`media-carousel__pagination__bullet--active`]]: true,
    });

    return {
      enabled: isPagination,
      clickable: true,
      horizontalClass: clsx({
        [styles[`media-carousel__pagination`]]: true,
      }),
      bulletClass: `swiper-pagination-bullet ${bulletClass}`,
      bulletActiveClass: bulletActiveClass,
    };
  };

  return (
    <Swiper
      modules={[EffectFade, EffectCoverflow, Autoplay, Navigation, Pagination]}
      autoHeight={true}
      pagination={pagination()}
      navigation={isNavigation}
      autoplay={autoplay ? { delay: 7000 } : false}
      loop={loop}
      effect={effect}
      fadeEffect={{ crossFade: effect === 'fade' }}
      onInit={onInit}
      className={clsx({
        [styles[`media-carousel`]]: true,
        [styles[`media-carousel--${theme}`]]: theme,
      })}
    >
      {children &&
        children.map((slide: ReactNode, index: number) => (
          <SwiperSlide key={index}>
            <div>{slide}</div>
          </SwiperSlide>
        ))}
    </Swiper>
  );
};
